import React from "react"
import Page from "../../components/page"
import TextLeft from "../../components/textLeft"
import { graphql } from "gatsby"
import TextRight from "../../components/textRight"

export default function AboutChildrenAlbums({ location, data }) {
  return (
    <Page title={"Children Albums – PhotoTailors"} location={location}>
      <TextLeft
        title={<div>Children <br/> Albums</div>}
        subtitle=""
        text={
          <div>
            Create your personalized stunning album now!
            <br/>
            <br/>
            This is the perfect gift or the perfect memory, something to be cherished for years to come.
            <br/>
            <br/>
            Every album is handcrafted by our skilled artisans using only the best available materials. We’re
            raising the bar for both beauty and quality!
          </div>
        }
        data={data}
        image={"childrenAlbumsAboutAlbums"}
      />
      <TextRight
        title={<div>Perfect<br/>memories</div>}
        subtitle="turn your photos into a photo album"
        text={
          <div>
            You can transform your photo gallery into a stunning album.
        </div>
        }
        data={data}
        image={"childrenAlbumsAboutMemories"}
      />
      <TextLeft
        title={<div>Lay <br/> FLAT TECHNOLOGY</div>}
        subtitle=""
        text="Our binding technology ensures there is no gap between pages. This flawless finish translates into a beautiful, seamless visual experience. You can thus enjoy all your photos and design in full. Layflat albums are great to showcase impressive panoramic spreads."
        data={data}
        image={"childrenAlbumsAboutLayflat"}
      />
      <TextRight
        title={<div>Thick <br/> Pages</div>}
        subtitle="rigid and durable"
        text="Printed images are fused onto a hard heavyweight board core. The thick, robust core doesn’t bend or curl, giving your album an elegant feel. It is the elite’s preferred choice, reserved only for your most exceptional and defining moments."
        data={data}
        image={"childrenAlbumsAboutThick"}
      />
      <TextLeft
        title={<div>Cover <br/> styles</div>}
        subtitle=""
        text="Each unique cover style has its own personality – which of them matches your taste? "
        data={data}
        image={"childrenAlbumsAboutCoverStyle"}
      />

    </Page>
  )
}


export const query = graphql`
  query {
    albumsAboutHandmade: file(relativePath: { eq: "albumsAboutHandmade.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
   albumsAboutPrinting: file(relativePath: { eq: "albumsAboutPrinting.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  
  albumsAboutLayflat: file(relativePath: { eq: "albumsAboutLayflat.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
   
   albumsAboutThickPages: file(relativePath: { eq: "albumsAboutThickPages.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  } 
  
  albumsAboutFabrics: file(relativePath: { eq: "albumsAboutFabrics.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  } 
  
  albumsAboutStyles: file(relativePath: { eq: "albumsAboutStyles.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  
  albumsAboutBoxes: file(relativePath: { eq: "albumsAboutBoxes.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  
  albumsAboutBags: file(relativePath: { eq: "albumsAboutBags.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 900, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
    
    guestbooksAboutAlbums: file(relativePath: { eq: "guestbooksAboutAlbums.jpg" }) { 
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    } 
    
    guestbooksAboutLayflat: file(relativePath: { eq: "guestbooksAboutLayflat.jpg" }) { 
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
     
     childrenAlbumsAboutAlbums: file(relativePath: { eq: "childrenAlbumsAboutAlbums.jpg" }) { 
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    childrenAlbumsAboutMemories: file(relativePath: { eq: "childrenAlbumsAboutMemories.jpg" }) { 
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
   childrenAlbumsAboutLayflat: file(relativePath: { eq: "childrenAlbumsAboutLayflat.jpg" }) { 
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    childrenAlbumsAboutThick: file(relativePath: { eq: "childrenAlbumsAboutThick.jpg" }) { 
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    childrenAlbumsAboutCoverStyle: file(relativePath: { eq: "childrenAlbumsAboutCoverStyle.jpg" }) { 
      childImageSharp {
        fluid(maxWidth: 900, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
   
   
  }
`